import React from 'react';

function App() {

  var attempts = 0;

  return (
    <div id="welcome">
      <h1>Les Oublis de l'Info</h1>

      <div id="slogan">
        Parce qu'il faut bien quelqu'un pour en parler.
      </div>

      <div id="soon" onClick={(e) => {

        // Détecte le div
        var element = document.getElementById("soon");

        // Ajoute un essai
        attempts++;

        // Si l'utilisateur essaye plus d'une fois
        if (attempts == 2) {
          element.textContent = "On se répête : bientôt !"
        }
        else if (attempts > 2) {
          element.textContent = "Pauvre fou."
        }
        else {
          element.textContent = "Nous avons dit : bientôt !"
        }
      
      }}>Bientôt</div>

      <div id="twitter">
        <a href="https://twitter.com/midraz">
          <img src="https://icon-library.net/images/twitter-icon-white/twitter-icon-white-17.jpg" alt="Twitter Logo" />
        </a>
      </div>
    </div>
  );
}

export default App;
